<template>
  <div>
    <b-modal
      id="mdlComentario"
      v-model="showComentarioModal"
      :title="isTitle"
      ok-title="Guardar"
      cancel-title="Cancelar"
      cancel-variant="secondary"
      ok-variant="primary"
      no-close-on-backdrop
      centered
      size="lg"
      hide-footer
      @ok="okModal"
      @cancel="closeModal"
      @close="closeModal"
    >
      <validation-observer ref="comentarioModal">
        <b-form>
          <b-overlay
            :show="isSaveOverlay"
            :rounded="overlayConfig.rounded"
            :variant="overlayConfig.variant"
            :opacity="overlayConfig.opacity"
            :blur="overlayConfig.blur"
          >
            <b-row>
              <b-col md="12">
                <b-form-group id="frmComentario">
                  <validation-provider
                    name="comentario"
                    rules="required"
                  >
                    <div slot-scope="{ failedRules }">
                      <textarea
                        id="txtObservacion"
                        v-model="spaComentario"
                        rows="4"
                        class="form-control form-control-alternative"
                        :disabled="isEditComentarioModal"
                        :maxlength="255"
                      />
                      <span
                        v-if="failedRules.required"
                        class="text-danger"
                      >Comentario obligatorio.</span>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-overlay>
        </b-form>
      </validation-observer>
      <div class="modal-footer">
        <b-button
          v-if="!isEditComentarioModal"
          variant="danger"
          squared
          @click="closeModal"
        >
          Cancelar
        </b-button>
        <b-button
          v-if="!isEditComentarioModal"
          variant="primary"
          squared
          @click="okModal"
        >
          Aceptar
        </b-button>
        <b-button
          v-if="isEditComentarioModal"
          type="button"
          squared
          @click="closeModal"
        >
          cerrar
        </b-button>
      </div>

    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, confirmed,
} from '@validations'

import { mapGetters } from 'vuex'

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    showComentarioModal: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data: () => ({
    isTitle: '',
    isEditComentarioModal: false,
    isSaveOverlay: false,
    spaComentario: '',
    overlayConfig: {
      rounded: 'sm',
      variant: 'transparent',
      opacity: '0.5',
      blur: '5px',
      message: 'Buscando lista de adjuntos',
    },
    required,
    confirmed,

    editorOption: {
      modules: {
        toolbar: [
          [{ size: ['small', false, 'large', 'huge'] }],
          ['bold', 'italic', 'underline', 'strike'],
          [
            { align: '' },
            { align: 'center' },
            { align: 'right' },
            { align: 'justify' },
          ],
          [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
          [{ indent: '-1' }, { indent: '+1' }],
          [{ color: [] }, { background: [] }],
        ],
      },
      theme: 'snow',
      readOnly: false,
      placeholder: 'Cargue un comentario...',
    },
  }),
  computed: {
    ...mapGetters({
      user: 'authUser/getUsuario',
    }),
  },
  created() {
    this.isTitle = 'Motivo del movimiento'
  },
  methods: {
    validatioComentarioModal() {
      this.$refs.comentarioModal.validate().then(success => {
        if (success) {
          if (this.spaComentario !== undefined) {
            this.$emit('contentComentarioModal', { spaComentario: this.spaComentario })
          }
        }
      })
    },
    closeModal(bvModalEvt) {
      this.$emit('contentComentarioModal', { spaComentario: null })
      bvModalEvt.preventDefault()
    },
    okModal(bvModalEvt) {
      this.validatioComentarioModal()
      bvModalEvt.preventDefault()
    },
  },
}
</script>
