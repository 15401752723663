/**
 * Dado un string con acentos, los remueve por su caracter sin acento
 * @example removeAccentsFromString('ÁáÑñ') -> 'AaNn'
 * @param {String} string
 * @returns {String} el string sin acentos
 */
export function removeAccentsFromString(string) {
  const str2 = string.normalize('NFD')
  const str3 = str2.replace(/[\u0300-\u036f]/g, '')
  return str3
}
