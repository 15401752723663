import axios from 'axios'
import {
  getStockBySpecs,
  postSaveStock,
  putUpdateStok,
  getReporteExcel,
  postReporteFechasStockExcel,
} from '@/utils/paths'
import { removeAccentsFromString } from '@/utils/stringUtils'

const pathGetStockBySpecs = getStockBySpecs
const pathPostSaveStock = postSaveStock
const pathPutUpdateStok = putUpdateStok
const pathGetReporteExcel = getReporteExcel
const pathPostReporteFechasStockExcel = postReporteFechasStockExcel

export function stockResource() {
  function getByFilterStockSpecs(filter, page) {
    let filterDto = ''
    if (filter) {
      filterDto = `&search=(nombre:'*${removeAccentsFromString(filter)}*')`
    }
    return new Promise((resolve, reject) => {
      axios
        .get(`${pathGetStockBySpecs}?page=${page.page}&size=${page.size}&sort=${page.sort}${filterDto}`)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }

  function getReporteStock() {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetReporteExcel, { responseType: 'blob' })
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }

  function saveStock(productoDto) {
    return new Promise((resolve, reject) => {
      axios
        .post(pathPostSaveStock, productoDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function updateStock(productoDto) {
    return new Promise((resolve, reject) => {
      axios
        .put(pathPutUpdateStok + productoDto.id, productoDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }

  function postReporteFechasStock(fechas) {
    return new Promise((resolve, reject) => {
      axios
        .post(pathPostReporteFechasStockExcel, fechas, { responseType: 'blob' })
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  return {
    getByFilterStockSpecs,
    saveStock,
    updateStock,
    getReporteStock,
    postReporteFechasStock,
  }
}
