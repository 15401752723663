<template>
  <b-card>
    <b-row>
      <b-col md="6">
        <h4>Gestión de productos</h4>
      </b-col>
    </b-row>
    <br>
    <b-row>
      <b-col md="6">
        <b-form @submit.prevent="onSubmit">

          <b-input-group>
            <b-input-group-append>
              <b-button
                v-if="hasContent"
                id="btnClearSearchProducto"
                v-b-tooltip.hover="'Limpiar filtro'"
                class="closeStyle"
                size="sm"
                @click="()=>clearSearchProducto()"
              >

                <font-awesome-icon icon="fa-solid fa-eraser" />
              </b-button>
            </b-input-group-append>
            <b-form-input
              id="txtFiltro"
              v-model="filtro"
              :disabled="isBusyBuscando || isEditing || isAddingNew"
              autocomplete="off"
              placeholder=" Búsqueda por ( nombre producto )"
            />
            <!--<div class="align-self-end ml-auto">-->
            <b-input-group-prepend>
              <b-button
                id="btnSearchStockProducto"
                v-b-tooltip.hover
                size="sm"
                title="Buscar producto/stock"
                variant="primary"
                :disabled="isBusyBuscando || isEditing || isAddingNew"
                @click="buscarStockProducto()"
              >
                <feather-icon icon="SearchIcon" />
              </b-button>
            </b-input-group-prepend>

          </b-input-group>
        </b-form>
      </b-col>
      <b-col
        md="6"
        class="text-right"
      >

        <b-button
          v-if="permisosIns.includes(nameModulo)"
          id="btnAddProducto"
          :disabled="isBusyBuscando || isEditing || isAddingNew"
          squared
          size="sm"
          title="Nuevo producto"
          variant="success"
          @click="()=>addProducto()"
        >
          <feather-icon icon="PlusIcon" />
        </b-button>

        <b-button
          v-if="permisosVer.includes(nameModulo)"
          id="btnDownloadReporteExcel"
          squared
          size="sm"
          :disabled="isDownloading"
          title="Descargar listado completo en formato excel"
          variant="primary"
          @click="()=>downloadReporteExcel()"
        >
          <font-awesome-icon
            v-if="!isDownloading"
            icon="fa-solid fa-file-export"
          />
          <span
            v-if="isDownloading"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />
        </b-button>

        <b-button
          v-if="permisosIns.includes(nameModulo)"
          id="btnAddProducto"
          :disabled="isBusyBuscando || isEditing || isAddingNew"
          squared
          size="sm"
          title="Exportar movimientos"
          @click="()=>openBusquedaModal()"
        >
          <font-awesome-icon icon="fa-window-restore" />
        </b-button>

      </b-col>
    </b-row>
    <br>
    <b-row>

      <b-col md="12">
        <!--ref="refTblStock"-->
        <validation-observer ref="validationStock">
          <b-form>
            <b-table
              id="tblStock"
              ref="refTblStock"
              :busy="isBusyBuscando"
              :items="tableDataStock"
              :fields="fieldsStock"
              outlined
              bordered
              show-empty
              striped
              small
              sticky-header="70vh"
              :no-border-collapse="true"
              empty-text="No se encontraron resultados para esta búsqueda"
            >

              <template v-slot:table-busy>
                <div class="text-center">
                  <b-spinner />
                </div>
              </template>
              <template #cell(activo)="row">
                <b-form-checkbox
                  v-model="tableDataStock[row.index].activo"
                  class="custom-control-success"
                  name="check-button"
                  :disabled="!row.item.isEdit || row.item.isProcesando"
                />
              </template>
              <!--Columna Producto-->
              <template #cell(producto)="row">
                <b-form-input
                  v-if="row.item.isEdit"
                  :id="`producto-input-id-${row.index}`"
                  :key="`producto-input-key-${row.index}`"
                  v-model="tableDataStock[row.index].producto"
                  class="form-control form-control-sm rounded-0"
                  autocomplete="off"
                  :disabled="row.item.isProcesando"
                  trim
                  maxlength="50"
                  :state="row.item.validProducto ? null: false"
                  @input="(e) => checkStringPorducto(e,row)"
                />
                <small v-else>{{ row.value }}</small>
              </template>

              <!--Columna Total-->
              <template #cell(total)="row">
                <p
                  class="m-0"
                  :style="evaluarCantidad(totalStockRow(row), row)"
                >
                  {{ totalStockRow(row) }}
                </p>
              </template>

              <template v-slot:cell()="{ item, index, field: { key } }">
                <b-form-input
                  v-if="item.isEdit"
                  :id="`input_id_${index}`"
                  :key="`input-key-${index}`"
                  v-model="tableDataStock[index][key].cantidad"
                  class="form-control form-control-sm rounded-0"
                  autocomplete="off"
                  :disabled="item.isProcesando"
                  :state="tableDataStock[index][key].validity ? null: false"
                  @input="(e) => inputHandler(e, index, key, tableDataStock[index][key], tableDataStock[index])"
                />
                <span v-else>{{ tableDataStock[index][key].cantidad }}</span>
              </template>

              <!--Columna botones acciones-->

              <template #cell(actions)="row">
                <div
                  class="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <b-button
                    v-if="permisosDel.includes(nameModulo) && row.item.productoId === null"
                    size="sm"
                    @click="quitarProducto(row)"
                  >
                    <font-awesome-icon icon="fa-solid fa-xmark" />
                  </b-button>
                  <!--v-b-tooltip.hover.righttop="!tableDataStock[row.index].isEdit? 'Editar':'Cancelar'"-->
                  <b-button
                    v-if="permisosUpd.includes(nameModulo) && row.item.productoId !==null"
                    size="sm"
                    :disabled="row.item.disabled"
                    :variant="!tableDataStock[row.index].isEdit? 'secondary': 'info'"
                    @click="editRowHandler(row.index), saveDtoOriginal(row.item)"
                  >
                    <font-awesome-icon
                      v-if="!tableDataStock[row.index].isEdit"
                      icon="fa-regular fa-pen-to-square"
                    />
                    <font-awesome-icon
                      v-if="tableDataStock[row.index].isEdit"
                      icon="fa-solid fa-xmark"
                    />

                  </b-button>
                  <!--v-b-tooltip.hover.righttop="'Guardar'"-->
                  <b-button
                    v-if="permisosUpd.includes(nameModulo) && tableDataStock[row.index].isEdit"
                    size="sm"
                    :disabled="disableButton(row)"
                    @click="validarForm(row.item, row.index, $event.target)"
                  >
                    <font-awesome-icon
                      v-if="!row.item.isProcesando"
                      icon="fa-solid fa-floppy-disk"
                    />
                    <span
                      v-if="row.item.isProcesando"
                      class="spinner-border spinner-border-sm"
                      aria-hidden="true"
                    />
                  </b-button>
                </div>
              </template>

            </b-table>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>

    <b-row>
      <div class="align-self-end ml-auto">
        <b-col md="12">
          <b-input-group>

            <b-input-group-append>
              <b-form-select
                v-model="pageStock.size"
                :clearable="false"
                title="Seleccione la cantidad de resultados por página"
                size="sm"
                :options="optionsPaginationSize"
                :disabled="isBusyBuscando"
                @change="buscarStockProducto()"
              />
            </b-input-group-append>
            <div class="align-self-end ml-auto">
              <b-col md="12">
                <b-pagination
                  id="pgnStock"
                  v-model="currentPage"
                  :total-rows="pageTotalElements"
                  :per-page="pageSize"
                  :disabled="isBusyBuscando"
                  @change="changePaginate"
                />
              </b-col>
            </div>
          </b-input-group>
        </b-col>
      </div>
      <div v-if="showComentarioModal">
        <comentario-modal
          :show-comentario-modal="showComentarioModal"
          @contentComentarioModal="contentComentarioModal"
        />
      </div>
      <div v-if="showBusquedaModal">
        <busqueda-modal
          :show-busqueda-modal="showBusquedaModal"
          @contentBusquedaModal="contentBusquedaModal"
        />
      </div>
    </b-row>
  </b-card>
</template>
<script>
import { mapGetters } from 'vuex'
import { stockResource } from '@/services/stockResource'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationObserver } from 'vee-validate'
import useAppConfig from '@core/app-config/useAppConfig'
import { saveAs } from 'file-saver'
import {
  required, confirmed, min, max,
} from '@validations'
import ComentarioModal from './ComentarioModal.vue'
import BusquedaModal from './BusquedaModal.vue'

export default {
  components: {
    ValidationObserver,
    ComentarioModal,
    BusquedaModal,
  },
  data() {
    return {
      required,
      confirmed,
      max,
      min,
      isDownloading: false,
      useLocalStorageLastSearch: false,
      cargando: false,
      pageTotalElements: 0,
      currentPage: 1,
      pageSize: 0,
      filtro: null,
      pageStock: {
        page: 0,
        size: 7,
        sort: 'nombre,asc',
      },
      inputConfig: {
        type: 'number',
      },
      overlayConfig: {
        rounded: 'sm',
        variant: 'transparent',
        opacity: '0.8',
        blur: '5px',
      },
      optionsPaginationSize: [5, 7, 10, 15, 20, 25, 30],
      itemPrevioAEdicion: null,
      preloadedDelegacion: [],
      nameModulo: 'ABM_STOCK',
      isBusyBuscando: false,
      isActivando: false,
      isDesactivando: false,
      tableDataStock: [],
      originalDataStock: [],
      productoName: null,
      active: null,
      fieldsStock: [],
      isAddingNew: false,
      cont: 0,
      isEditing: false,
      showComentarioModal: false,
      showBusquedaModal: false,
      indexUpdate: null,
      productoDtoUpdate: null,
    }
  },

  computed: {
    hasContent() {
      return (this.filtro != null && this.filtro.length > 0)
    },
    ...mapGetters(
      {
        permisosVer: 'getPermisosVer',
        permisosDel: 'getPermisosDel',
        permisosIns: 'getPermisosIns',
        permisosUpd: 'getPermisosUpd',
      },
    ),
  },
  created() {
    localStorage.setItem('lastPath', this.$route.path)
    this.preloadedDelegacion = this.$store.getters.getDelegacion
    this.initStockTableHeaders()
    if (this.useLocalStorageLastSearch) { this.applyLastSearch() }
    this.getStockFilter()
  },
  methods: {
    disableButton(data) {
      const dataLocal = data.item
      const invalid = element => {
        // por cada elemento verificamos que no sea null y ademas que si hay alguna validación en false, lo cual indica que no es valido y por eso retornamos invalid como TRUE
        if (element !== null && element.validity === false) {
          return true
        }
        return false
      }
      const invalidsStringInput = Object.values(dataLocal).some(invalid)

      if (!invalidsStringInput && dataLocal.validProducto) {
        return false
      }
      return true
    },
    checkStringPorducto(e, row) {
      const { producto } = row.item
      const reg = new RegExp('^[a-zA-Z0-9ñÑ ]*$')
      const resultRegValidation = reg.test(producto)
      if (resultRegValidation && producto !== '') {
        this.tableDataStock[row.index].validProducto = true
      } else {
        this.tableDataStock[row.index].validProducto = false
      }
    },
    checkFormInputValues(e, index, field, val) {
      const reg = new RegExp('^[0-9]*$')
      let statusChar = true // True es todo correcto
      let statusEmpty = false // false es todo correcto
      let finalStatus = true
      let maxLengthAlcanzado = false
      const resultRegValidation = reg.test(val.cantidad)
      if (val.cantidad.length > 8) {
        maxLengthAlcanzado = true
      }
      if (!resultRegValidation) {
        statusChar = false
      }
      if (val.cantidad === null || val.cantidad === '') {
        statusEmpty = true
      }
      if (!statusChar || statusEmpty || maxLengthAlcanzado) {
        this.tableDataStock[index][field].validity = false
        finalStatus = false
      } else {
        this.tableDataStock[index][field].validity = true
      }
      return finalStatus
    },
    inputHandler(e, index, field, val, row) {
      return this.checkFormInputValues(e, index, field, val, row)
    },
    getColorCantidad(stock, key, row) {
      if (row.activo) {
        return stock.cantidad === 0 ? 'text-center text-danger' : 'text-center text-secondary'
      }
      return 'text-center text-secondary'
    },
    evaluarCantidad(cantidad, row) {
      const { skin } = useAppConfig()
      let whiteBlack = 'black'
      if (skin.value === 'dark') {
        whiteBlack = 'white'
      }
      if (!row.item.activo) {
        return 'color: gray;'
      }
      return (cantidad === 0) ? 'color: red;' : `color: ${whiteBlack}`
    },
    totalStockRow(row) {
      let total = 0

      this.preloadedDelegacion.forEach(delegacion => {
        const cant = row.item[delegacion.codigo].cantidad
        let converted = parseInt(cant, 10)

        if (Number.isNaN(converted)) {
          converted = 0
        }
        total += converted
      })
      return total
    },
    initStockTableHeaders() {
      this.fieldsStock.push({
        key: 'activo', label: 'Activo', thStyle: { width: '3%' }, tdClass: 'text-center',
      })
      this.fieldsStock.push({ key: 'producto', label: 'Producto', thStyle: { width: '20%' } })

      this.fieldsStock.push({
        key: 'total',
        label: 'Total',
        thStyle: { width: '3%' },
        thClass: 'text-center',
        tdClass: 'text-center',
        // tdClass: value => (value === 0 ? 'text-center text-danger' : 'text-center text-secondary'),
      })

      this.preloadedDelegacion.forEach(delegacion => {
        const columnaDelegacion = {
          key: delegacion.codigo,
          label: delegacion.nombreCustom,
          thStyle: { width: '8%' },
          thClass: 'text-center',
          tdClass: (item, key, row) => (this.getColorCantidad(item, key, row)),
        }
        this.fieldsStock.push(columnaDelegacion)
      })
      this.fieldsStock.push({
        key: 'actions', label: 'acciones', thStyle: { width: '3%' }, thClass: 'text-center',
      })
      this.fieldsStockCopy = [...this.fieldsStock]
    },
    applyLastSearch() {
      if (localStorage.getItem('abm_stock_lastSearch')) {
        this.filtro = localStorage.getItem('abm_stock_lastSearch')
      } else {
        this.filtro = null
      }
      if (localStorage.getItem('stock_page_size_lastset')) {
        this.pageStock.size = localStorage.getItem('stock_page_size_lastset')
      } else {
        this.pageStock.size = 7
      }
    },
    disableAll(value) {
      this.tableDataStock.forEach(prod => {
        const objIndex = this.tableDataStock.findIndex((obj => obj.productoId === prod.productoId))
        this.tableDataStock[objIndex].disabled = value
      })
    },
    async contentComentarioModal(comentario) {
      const comentarioDto = {}
      await this.changethis()
      if (comentario.spaComentario !== null) {
        this.productoDtoUpdate.comentario = comentario.spaComentario
        this.updateStockProducto(this.productoDtoUpdate, this.indexUpdate)
      } else {
        comentarioDto.spaComentario = null
      }
    },
    changethis() {
      this.showComentarioModal = false
      return null
    },
    async contentBusquedaModal() {
      await this.changethisBusqueda()
    },
    changethisBusqueda() {
      this.showBusquedaModal = false
      return null
    },
    openBusquedaModal() {
      this.showBusquedaModal = true
    },
    editRowHandler(index) {
      if (this.tableDataStock[index].isEdit === false) {
        // Si es (era) false, entonces est+a entrando a editar, por lo tanto salvo el contenido del ROW actual, por
        // si el usuario luego cancela la operación, usando este objecto para recuperar los valores originales.
        // En esta linea usaos JSON para garantizar una copia exacta y Deep.
        this.itemPrevioAEdicion = JSON.parse(JSON.stringify(this.tableDataStock[index]))
      }
      if (this.tableDataStock[index].isEdit === true) {
        // Si entro aca, es porque el usuario está cancelando la edición.
        // Reestablecemos los valores anteriores usando el backup itemPrevioAEdicion
        this.preloadedDelegacion.forEach(element => {
          this.tableDataStock[index].producto = this.itemPrevioAEdicion.producto
          this.tableDataStock[index].activo = this.itemPrevioAEdicion.activo
          this.tableDataStock[index][element.codigo].cantidad = this.itemPrevioAEdicion[element.codigo].cantidad
          this.tableDataStock[index][element.codigo].validity = true
        })
        // Limpiamos el objecto backup del original.
        // this.itemPrevioAEdicion = {}
      }

      this.isEditing = !this.isEditing
      this.tableDataStock.forEach(prod => {
        const objIndex = this.tableDataStock.findIndex((obj => obj.productoId === prod.productoId))
        this.tableDataStock[objIndex].disabled = !this.tableDataStock[objIndex].disabled
      })
      this.tableDataStock[index].isEdit = !this.tableDataStock[index].isEdit
      this.tableDataStock[index].disabled = !this.tableDataStock[index].disabled
      // this.$refs.refTblStock.refresh()
    },
    quitarProducto(row) {
      this.tableDataStock.splice(row.index, 1)
      this.tableDataStock[row.index].isProcesando = false
      this.isEditing = false
      this.disableAll(false)
    },
    scrollToRow(index) {
      const tbody = this.$refs.refTblStock.$el.querySelector('tbody')
      const row = tbody.querySelectorAll('tr')[index]
      row.scrollIntoView({ top: 0, behavior: 'smooth' })
    },
    addProducto() {
      this.scrollToRow(0)
      // window.scrollTo({ top: 0, behavior: 'smooth' })
      this.isEditing = true
      const stockObject = {
        producto: 'nombre',
        isEdit: true,
        productoId: null,
        activo: true,
        isProcesando: false,
        validProducto: true,
      }
      this.preloadedDelegacion.forEach(delegacion => {
        stockObject[delegacion.codigo] = {
          cantidad: 0,
          delegacion: delegacion.nombre,
          delegacionId: delegacion.id,
          stockId: null,
          validity: true,
        }
      })
      this.disableAll(true)
      this.tableDataStock.unshift(stockObject)
    },
    saveDtoOriginal(item) {
      this.productoName = item.producto
      this.active = item.activo
    },
    guardar(item, index) {
      const productoDto = {}
      productoDto.id = item.productoId
      productoDto.nombre = item.producto
      productoDto.activo = item.activo
      productoDto.listaStock = []
      this.preloadedDelegacion.forEach(delegacion => {
        const codigoDelegacion = delegacion.codigo
        const stock = {
          cantidad: null,
          cantidadPrevia: null,
          id: null,
          delegacion: {
            id: null,
            nombre: null,
            codigo: null,
          },
          producto: {
            id: null,
          },
        }

        stock.cantidad = item[codigoDelegacion].cantidad
        stock.id = item[codigoDelegacion].stockId
        stock.delegacion.id = item[codigoDelegacion].delegacionId
        stock.delegacion.nombre = item[codigoDelegacion].delegacion
        stock.delegacion.codigo = codigoDelegacion
        stock.producto.id = item.productoId
        if (stock.producto.id != null) {
          // Si el producto es nuevo (sin id), entonces no hace falta setear la cantidad previa de stock.
          stock.cantidadPrevia = this.itemPrevioAEdicion[codigoDelegacion].cantidad
        }
        productoDto.listaStock.push(stock)
      })
      if (productoDto.id !== null) {
        this.productoDtoUpdate = productoDto
        this.indexUpdate = index
        if (productoDto.nombre !== this.productoName || productoDto.activo !== this.active) {
          this.cont += 1
        }
        productoDto.listaStock.forEach(element => {
          if (element.cantidad !== element.cantidadPrevia) {
            this.cont += 1
          }
        })
        if (this.cont > 0) {
          this.showComentarioModal = true
        } else {
          this.editRowHandler(index)
        }
      } else {
        this.actionStockProducto(productoDto, item, index)
      }
      this.cont = 0
    },
    updateStockProducto(productoDto, index) {
      this.tableDataStock[index].isProcesando = true
      stockResource().updateStock(productoDto)
        .then(resultUpdateStock => {
          const objIndex = this.tableDataStock.findIndex((obj => obj.producto === resultUpdateStock.nombre))
          if (objIndex !== -1) {
            this.tableDataStock[objIndex].isEdit = false
            this.tableDataStock[objIndex].isProcesando = false
            resultUpdateStock.listaStock.forEach(stock => {
              this.tableDataStock[objIndex][stock.delegacion.codigo].stockId = stock.id
              this.tableDataStock[objIndex][stock.delegacion.codigo].cantidad = stock.cantidad
            })
          } else {
            console.error('Objeto ,objIndex, no encontrado', objIndex)
          }
          this.disableAll(false)
        }).catch(error => {
          if (error.data && error.data.exception && error.data.exception.length > 0) {
            error.data.exception.forEach(e => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: e,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: 'Error al modificar!',
                },
              })
            })
          }
        })
        .finally(() => {
          this.isEditing = false
          this.tableDataStock[index].isProcesando = false
        })
    },
    actionStockProducto(productoDto, item, index) {
      this.tableDataStock[index].isProcesando = true
      stockResource().saveStock(productoDto)
        .then(resultSaveStock => {
          const objIndex = this.tableDataStock.findIndex((obj => obj.producto === resultSaveStock.nombre))
          if (objIndex !== -1) {
            this.tableDataStock[objIndex].producto = resultSaveStock.nombre
            this.tableDataStock[objIndex].productoId = resultSaveStock.id
            this.tableDataStock[objIndex].isEdit = false
            this.tableDataStock[objIndex].isProcesando = false
            resultSaveStock.listaStock.forEach(stock => {
              this.tableDataStock[objIndex][stock.delegacion.codigo].stockId = stock.id
              this.tableDataStock[objIndex][stock.delegacion.codigo].cantidad = stock.cantidad
            })
          } else {
            console.error('Objeto ,objIndex, no encontrado', objIndex)
          }
          this.disableAll(false)
          this.isEditing = false
        }).catch(error => {
          if (error.data && error.data.exception && error.data.exception.length > 0) {
            error.data.exception.forEach(e => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: e,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: 'Error al guardar!',
                },
              })
            })
          }
        })
        .finally(() => {
          this.tableDataStock[index].isProcesando = false
        })
    },
    onSubmit() {
      if (!this.isBusyBuscando) {
        this.buscarStockProducto()
      }
    },
    clearSearchProducto() {
      this.filtro = ''
      this.buscarStockProducto()
    },
    buscarStockProducto() {
      if (this.useLocalStorageLastSearch) {
        if (this.filtro) {
          localStorage.setItem('abm_stock_lastSearch', this.filtro)
        } else {
          localStorage.setItem('abm_stock_lastSearch', '')
        }
        localStorage.setItem('stock_page_size_lastset', this.pageStock.size)
      }
      this.currentPage = 1
      this.pageStock.page = 0
      this.getStockFilter()
    },
    getStockFilter() {
      this.isBusyBuscando = true
      const finalList = []
      stockResource().getByFilterStockSpecs(this.filtro, this.pageStock)
        .then(resultGetByFilterStockSpecs => {
          resultGetByFilterStockSpecs.content.forEach(producto => {
            const stockObject = {
              isEdit: false,
              validProducto: true,
              isProcesando: false,
              producto: producto.nombre,
              productoId: producto.id,
              activo: producto.activo,
              total: 0,
              // _rowVariant: 'success',
            }
            producto.listaStock.forEach(itemStock => {
              stockObject[itemStock.delegacion.codigo] = {
                cantidad: itemStock.cantidad,
                delegacion: itemStock.delegacion.nombre,
                delegacionId: itemStock.delegacion.id,
                stockId: itemStock.id,
                validity: true,
              }
              stockObject.total += itemStock.cantidad
            })
            stockObject.disabled = false
            finalList.push(stockObject)
          })
          this.pageTotalElements = resultGetByFilterStockSpecs.totalElements
          this.pageNumber = resultGetByFilterStockSpecs.number
          this.pageSize = resultGetByFilterStockSpecs.size
          this.tableDataStock = finalList
        }).catch(error => {
          console.error('errors:', error)
          this.tableDataStock = []
        })
        .finally(() => {
          this.isBusyBuscando = false
          this.originalDataStock = JSON.parse(JSON.stringify(this.tableDataStock))
        })
    },
    changePaginate(item) {
      this.pageStock.page = item - 1
      this.isBusyBuscando = true
      this.getStockFilter()
    },
    downloadReporteExcel() {
      this.isDownloading = true
      const date = new Date()
      const fileDate = this.formatDateTime(date, 'YYYY_MM_DD_HH_mm_ssZZ', 'YYYY_MM_DD_HH_mm_ssZZ')
      stockResource().getReporteStock().then(resultado => {
        const blob = new Blob([resultado], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        saveAs(blob, `reporte-stock-Atacc${fileDate}.xlsx`)
      }).catch(error => {
        console.error(error)
      })
        .finally(() => {
          this.isDownloading = false
        })
    },
    validarForm(item, index, target) {
      this.guardar(item, index, target)
    },
  },
}
</script>
<style>
</style>
