<template>
  <div>
    <b-modal
      id="mdlBusqueda"
      v-model="showBusquedaModal"
      :title="isTitle"
      ok-title="Guardar"
      cancel-title="Cancelar"
      cancel-variant="secondary"
      ok-variant="primary"
      no-close-on-backdrop
      centered
      size="lg"
      hide-footer
      @ok="okModal"
      @cancel="closeModal"
      @close="closeModal"
    >
      <validation-observer ref="busquedaModal">
        <b-form>
          <b-overlay
            :show="isSaveOverlay"
            :rounded="overlayConfig.rounded"
            :variant="overlayConfig.variant"
            :opacity="overlayConfig.opacity"
            :blur="overlayConfig.blur"
          >
            <b-row>
              <b-col md="4">
                <b-form-group
                  id="frmDesde"
                  label="Fecha Desde"
                >
                  <validation-provider
                    name="valProvFechaDesde"
                    :rules="fechas.fechaHasta!==null ? 'required' : ''"
                  >
                    <div slot-scope="{ errors, failedRules }">
                      <b-form-datepicker
                        id="sltDesde"
                        v-model="fechas.fechaDesde"
                        v-b-tooltip.hover="'Fecha desde del movimiento'"
                        locale="es-AR"
                        :max="fechas.fechaHasta"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                        label-help="Use las teclas de flecha para navegar por el calendario"
                        label-current-month="Mes actual"
                        label-next-month="Mes siguiente"
                        label-next-year="Año siguiente"
                        label-no-date-selected="desde"
                        label-prev-month="Mes anterior"
                        label-prev-year="Año anterior"
                        :state="errors.length > 0 ? false : null"
                        :disabled="isBusyBuscando"
                        @input="onFechaDesdeChange()"
                      />
                      <small
                        v-if="failedRules.required"
                        class="text-danger"
                      >Campo obligatorio</small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  id="frmHasta"
                  label="Fecha Hasta"
                >
                  <validation-provider
                    name="valProvFechaDesde"
                    :rules="fechas.fechaDesde!==null ? 'required' : ''"
                  >
                    <div slot-scope="{ errors, failedRules }">
                      <b-form-datepicker
                        id="sltHasta"
                        v-model="fechas.fechaHasta"
                        v-b-tooltip.hover="'Fecha hasta del movimiento'"
                        :min="fechas.fechaDesde"
                        locale="es-AR"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                        label-help="Use las teclas de flecha para navegar por el calendario"
                        label-current-month="Mes actual"
                        label-next-month="Mes siguiente"
                        label-next-year="Año siguiente"
                        label-no-date-selected="hasta"
                        label-prev-month="Mes anterior"
                        label-prev-year="Año anterior"
                        :disabled="fechas.fechaDesde === null ? true: false || isBusyBuscando"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small
                        v-if="failedRules.required"
                        class="text-danger"
                      >Campo obligatorio</small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                md="1"
                class="text-right"
              >
                <b-button
                  id="btnDownloadReporteExcel"
                  class="custom-button"
                  squared
                  size="sm"
                  :disabled="isDownloading"
                  title="Descargar listado en formato excel"
                  variant="primary"
                  @click="()=>downloadReporteExcel()"
                >
                  <font-awesome-icon
                    v-if="!isDownloading"
                    icon="fa-solid fa-file-export"
                  />
                  <span
                    v-if="isDownloading"
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />
                </b-button>

              </b-col>
            </b-row>
          </b-overlay>
        </b-form>
      </validation-observer>
      <div class="modal-footer">
        <b-button
          v-if="!isEditBusquedaModal"
          variant="primary"
          squared
          @click="closeModal"
        >
          Cerrar
        </b-button>
        <b-button
          v-if="isEditBusquedaModal"
          type="button"
          squared
          @click="closeModal"
        >
          Cerrar
        </b-button>
      </div>

    </b-modal>
  </div>
</template>

<script>
import { stockResource } from '@/services/stockResource'
import { saveAs } from 'file-saver'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, confirmed,
} from '@validations'

import { mapGetters } from 'vuex'

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    showBusquedaModal: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data: () => ({
    isTitle: '',
    isEditBusquedaModal: false,
    fechas: {
      fechaDesde: new Date(),
      fechaHasta: new Date(),
    },
    isBusyBuscando: false,
    isSaveOverlay: false,
    isDownloading: false,
    overlayConfig: {
      rounded: 'sm',
      variant: 'transparent',
      opacity: '0.5',
      blur: '5px',
      message: 'Buscando lista de adjuntos',
    },
    required,
    confirmed,
  }),
  computed: {
    ...mapGetters({
      user: 'authUser/getUsuario',
    }),
    maxDate() {
      const now = new Date()
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      const maxDate = new Date(today)
      return maxDate
    },
  },
  created() {
    this.isTitle = 'Exportar movimientos realizados'
  },
  methods: {
    validatioBusquedaModal() {
      this.$refs.busquedaModal.validate().then(success => {
        if (success) {
          if (this.spaBusqueda !== undefined) {
            this.$emit('contentBusquedaModal')
          }
        }
      })
    },
    onFechaDesdeChange() {
      if (this.fechas.fechaDesde > this.fechas.fechaHasta) {
        this.fechas.fechaHasta = this.fechas.fechaDesde
      }
    },
    downloadReporteExcel() {
      this.isDownloading = true
      this.fechas.fechaDesde = this.formatDateToJavaT(this.fechas.fechaDesde, 'YYYYMMDD', 'DD-MM-YYYY 00:00')
      this.fechas.fechaHasta = this.formatDateToJavaT(this.fechas.fechaHasta, 'YYYYMMDD', 'DD-MM-YYYY 23:59')
      const date = new Date()
      const fileDate = this.formatDateTime(date, 'YYYY_MM_DD_HH', 'YYYY_MM_DD_HH')
      stockResource().postReporteFechasStock(this.fechas)
        .then(resultado => {
          const blob = new Blob([resultado], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
          saveAs(blob, `reporte_movimientos_de_stock_Atacc${fileDate}.xlsx`)
        }).catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.isDownloading = false
          this.closeModal()
        })
    },
    closeModal() {
      this.$emit('contentBusquedaModal')
    },
    okModal() {
      this.validatioBusquedaModal()
    },
  },
}
</script>
<style>
.custom-button {
  margin-top: 27px;
  width: 40px;
  height: 40px;
}
</style>
